/* Variable */
/* Mixins */
/* Button */
.btn {
  font-weight: 300;
  border-radius: 3px;
}
.btn:focus,
.btn:active:focus,
.btn.active:focus {
  outline: none;
}
.btn:active,
.btn.active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.1);
}
.btn-icon,
.btn.btn-icon {
  display: inline-block;
  width: 28px;
  height: 28px;
  padding: 0;
  border: none;
  line-height: 28px;
  text-align: center;
  font-size: 14px;
}
.btn-icon.btn-xs,
.btn.btn-icon.btn-xs {
  width: 16px;
  height: 16px;
  font-size: 8px;
  line-height: 16px;
}
.btn-icon.btn-sm,
.btn.btn-icon.btn-sm {
  width: 22px;
  height: 22px;
  font-size: 11px;
  line-height: 22px;
}
.btn-icon.btn-lg,
.btn.btn-icon.btn-lg {
  width: 34px;
  height: 34px;
  font-size: 17px;
  line-height: 34px;
}
.btn-icon > .pull-left,
.btn.btn-icon > .pull-left,
.btn-icon > .pull-right,
.btn.btn-icon > .pull-right {
  line-height: 1.428571429;
}
.btn-circle,
.btn.btn-circle {
  border-radius: 50%;
}
.btn-scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 25px;
  z-index: 1020;
}
.btn-block {
  padding-left: 12px;
  padding-right: 12px;
}
/* 
    Button Generator (inside _mixins.less) 
    ----------------
    .generate-button-styling(@buttonClassName; @defaultColor; @hoverColor;); 
*/
.btn.btn-default {
  color: #fff;
  background: #b6c2c9;
  border-color: #b6c2c9;
}
.btn.btn-default:hover,
.btn.btn-default:focus,
.btn.btn-default:active,
.btn.btn-default.active {
  background: #929ba1;
  border-color: #929ba1;
}
.open .dropdown-toggle.btn-default {
  background: #929ba1;
  border-color: #929ba1;
}
.btn-group .btn.btn-default:not(.active) + .btn.btn-default,
.input-group-btn .btn.btn-default:not(.active) + .btn.btn-default {
  border-left-color: #929ba1;
}
.btn.btn-inverse {
  color: #fff;
  background: #2d353c;
  border-color: #2d353c;
}
.btn.btn-inverse:hover,
.btn.btn-inverse:focus,
.btn.btn-inverse:active,
.btn.btn-inverse.active {
  background: #242a30;
  border-color: #242a30;
}
.open .dropdown-toggle.btn-inverse {
  background: #242a30;
  border-color: #242a30;
}
.btn-group .btn.btn-inverse:not(.active) + .btn.btn-inverse,
.input-group-btn .btn.btn-inverse:not(.active) + .btn.btn-inverse {
  border-left-color: #242a30;
}
.btn.btn-primary {
  color: #fff;
  background: #348fe2;
  border-color: #348fe2;
}
.btn.btn-primary:hover,
.btn.btn-primary:focus,
.btn.btn-primary:active,
.btn.btn-primary.active {
  background: #2a72b5;
  border-color: #2a72b5;
}
.open .dropdown-toggle.btn-primary {
  background: #2a72b5;
  border-color: #2a72b5;
}
.btn-group .btn.btn-primary:not(.active) + .btn.btn-primary,
.input-group-btn .btn.btn-primary:not(.active) + .btn.btn-primary {
  border-left-color: #2a72b5;
}
.btn.btn-success {
  color: #fff;
  background: #00acac;
  border-color: #00acac;
}
.btn.btn-success:hover,
.btn.btn-success:focus,
.btn.btn-success:active,
.btn.btn-success.active {
  background: #008a8a;
  border-color: #008a8a;
}
.open .dropdown-toggle.btn-success {
  background: #008a8a;
  border-color: #008a8a;
}
.btn-group .btn.btn-success:not(.active) + .btn.btn-success,
.input-group-btn .btn.btn-success:not(.active) + .btn.btn-success {
  border-left-color: #008a8a;
}
.btn.btn-warning {
  color: #fff;
  background: #f59c1a;
  border-color: #f59c1a;
}
.btn.btn-warning:hover,
.btn.btn-warning:focus,
.btn.btn-warning:active,
.btn.btn-warning.active {
  background: #c47d15;
  border-color: #c47d15;
}
.open .dropdown-toggle.btn-warning {
  background: #c47d15;
  border-color: #c47d15;
}
.btn-group .btn.btn-warning:not(.active) + .btn.btn-warning,
.input-group-btn .btn.btn-warning:not(.active) + .btn.btn-warning {
  border-left-color: #c47d15;
}
.btn.btn-danger {
  color: #fff;
  background: #ff5b57;
  border-color: #ff5b57;
}
.btn.btn-danger:hover,
.btn.btn-danger:focus,
.btn.btn-danger:active,
.btn.btn-danger.active {
  background: #cc4946;
  border-color: #cc4946;
}
.open .dropdown-toggle.btn-danger {
  background: #cc4946;
  border-color: #cc4946;
}
.btn-group .btn.btn-danger:not(.active) + .btn.btn-danger,
.input-group-btn .btn.btn-danger:not(.active) + .btn.btn-danger {
  border-left-color: #cc4946;
}
.btn.btn-info {
  color: #fff;
  background: #49b6d6;
  border-color: #49b6d6;
}
.btn.btn-info:hover,
.btn.btn-info:focus,
.btn.btn-info:active,
.btn.btn-info.active {
  background: #3a92ab;
  border-color: #3a92ab;
}
.open .dropdown-toggle.btn-info {
  background: #3a92ab;
  border-color: #3a92ab;
}
.btn-group .btn.btn-info:not(.active) + .btn.btn-info,
.input-group-btn .btn.btn-info:not(.active) + .btn.btn-info {
  border-left-color: #3a92ab;
}
.btn.btn-white {
  font-weight: normal;
  color: #333;
  background: #ffffff;
  border-color: #e2e7eb;
}
.btn.btn-white:hover,
.btn.btn-white:focus,
.btn.btn-white:active,
.btn.btn-white.active {
  background: #e2e7eb;
  border-color: #d8dde1;
}
.btn.btn-white.btn-white-without-border {
  border-color: #ffffff;
}
.btn.btn-white.btn-white-without-border.active,
.btn.btn-white.btn-white-without-border.active:hover,
.btn.btn-white.btn-white-without-border.active:focus {
  border-color: #ddd;
}
.btn.btn-white.btn-white-without-border:hover,
.btn.btn-white.btn-white-without-borderfocus {
  border-color: #eee;
}
.open .dropdown-toggle.btn-white {
  background: #e2e7eb;
  border-color: #d8dde1;
}
.btn-group .btn.btn-white:not(.active) + .btn.btn-white,
.input-group-btn .btn.btn-white:not(.active) + .btn.btn-white {
  border-left-color: #eee;
}
/* Form Elements */
.form-control {
  border: 1px solid #ccd0d4;
  font-size: 12px;
  border-radius: 3px;
  box-shadow: none;
}
.form-control.input-white {
  background: #fff;
  border-color: #fff;
}
.form-control.input-white:focus {
  box-shadow: none;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background: #e5e9ed;
  opacity: 0.6;
}
.form-control[disabled]:focus,
.form-control[readonly]:focus,
fieldset[disabled] .form-control:focus {
  box-shadow: none;
  border: 1px solid #ccd0d4;
}
.form-control:focus {
  border-color: #9fa2a5;
  box-shadow: none;
}
.form-horizontal.form-bordered .form-group {
  border-bottom: 1px solid #eee;
  margin: 0;
}
.form-horizontal.form-bordered .form-group:last-child {
  border-bottom: 0;
}
.form-horizontal.form-bordered .form-group > .control-label {
  padding: 22px 15px 15px;
}
.form-horizontal.form-bordered .form-group > div {
  padding: 15px;
}
.form-horizontal.form-bordered .form-group > div {
  border-left: 1px solid #eee;
}
.form-horizontal.form-bordered .form-group > .control-label {
  border-right: 1px solid #eee;
  margin-right: -1px;
}
.form-horizontal.form-bordered .has-feedback .form-control-feedback {
  top: 15px;
}
select.form-control {
  border-color: #ccd0d4;
}
select[multiple].form-control {
  border-color: #ccd0d4;
}
label {
  font-weight: 500;
}
.input-group-addon {
  background: #e2e7eb;
  border: none;
}
legend {
  padding-bottom: 3px;
  border-bottom: 1px solid #e2e7eb;
}
#cancel-search-btn {
  margin-top: 8px;
  position: absolute;
  right: 75px;
}
.per-page-dropdown,
.search-form-input {
  border: 1px solid #ccd0d4;
  background: #fff;
  font-size: 12px;
  padding: 6px 12px;
  line-height: 1.42857143;
  color: #555;
  border-radius: 3px;
}
.per-page-dropdown {
  margin-right: 10px;
  height: 34px !important;
  width: auto !important;
}
.search-form-input {
  border-color: #ccd0d4;
  height: 34px;
  margin-left: 10px;
}
.search-form-input:focus {
  outline: 0;
  border-color: #9fa2a5;
  box-shadow: none;
}
/* Pagination & pager */
.pager li > a,
.pager li > span,
.pagination > li > a {
  border-color: #e2e7eb;
  color: #242a30;
}
.pager.pager-without-border li > a,
.pager.pager-without-border li > span,
.pagination.pagination-without-border > li > a {
  border-color: #ffffff;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus,
.pager > .disabled > span,
.pager > .disabled > a {
  opacity: 0.6;
  border-color: #ddd;
}
.pagination > li > a {
  color: #242a30;
  margin-left: 5px;
  border-radius: 3px !important;
}
.pagination > li:first-child > a {
  margin-left: 0;
}
.pagination-sm > li > a,
.pagination-sm > li > span {
  font-size: 10px;
  margin-left: 4px;
}
.pagination-lg > li > a,
.pagination-lg > li > span {
  font-size: 14px;
  margin-left: 6px;
}
.pager li > a:hover,
.pager li > a:focus,
.pager li > span:hover,
.pager li > span:focus,
.pagination > li > a:hover,
.pagination > li > a:focus {
  color: #242a30;
  background: #e2e7eb;
  border-color: #d8dde1;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background: #242a30 !important;
  border-color: #242a30 !important;
}
/* Panel */
.panel {
  border: none;
  box-shadow: none;
  border-radius: 3px;
}
.panel.panel-no-rounded-corner .panel-heading,
.panel.panel-no-rounded-corner .panel-body,
.panel.panel-no-rounded-corner .panel-footer {
  border-radius: 0 !important;
}
.panel .tab-content {
  border-radius: 0 0 3px 3px;
}
.panel-heading {
  padding: 10px 15px;
  border: none;
}
.panel-heading + .table,
.panel-heading + .slimScrollDiv {
  border-top: 1px solid #eee;
}
.panel-heading .panel-heading-btn {
  float: right;
}
.panel-heading .panel-heading-btn > a {
  margin-left: 8px;
}
.panel-heading .btn-group .btn {
  margin-top: -7px;
}
.panel-heading .btn-group .btn.btn-sm {
  margin-top: -5px;
}
.panel-heading .btn-group .btn.btn-xs {
  margin-top: -1px;
}
.panel-heading .label.pull-left,
.panel-heading .label.pull-right {
  line-height: 15px;
}
.panel-heading .progress.pull-right,
.panel-heading .progress.pull-left {
  width: 40%;
  min-width: 120px;
}
.panel-heading + .alert {
  margin-bottom: 0;
  border-radius: 0;
}
.panel-heading .nav-tabs {
  margin-top: -10px;
  margin-right: -15px;
}
.panel-heading .nav-tabs > li > a {
  padding: 10px 15px;
  line-height: 20px;
}
.panel-with-tabs.panel-default .panel-heading {
  background: #c1ccd1;
  color: #333;
}
.panel-title {
  line-height: 20px;
  font-size: 12px;
}
.panel-title .accordion-toggle {
  margin: -10px -15px;
  padding: 10px 15px;
}
.panel-title .accordion-toggle.accordion-toggle-styled .fa:before {
  content: '\f056';
}
.panel-title .accordion-toggle.accordion-toggle-styled.collapsed .fa:before {
  content: '\f055';
}
.panel-title .pull-right {
  line-height: 20px;
}
.panel-group .panel {
  border-radius: 3px;
}
.form-control + .panel-footer {
  border-top: none;
}
.panel-body {
  padding: 15px;
}
.panel-body.no-border {
  border: none !important;
}
.panel-body.panel-table,
.panel-body.panel-form,
.panel-body.no-padding,
.panel-body.panel-full-width {
  padding: 0 !important;
}
.panel-body.with-table > .table {
  border: 0;
  margin: 0;
}
.panel-body.with-table > .table tr:last-child th,
.panel-body.with-table > .table tr:last-child td {
  border-bottom: 0;
}
.panel-default > .panel-heading + .panel-collapse .panel-body {
  border-top: 1px solid #eee;
}
.panel-footer {
  background: #fff;
  border-top: 1px solid #eee;
}
.panel-default > .panel-heading {
  background: #fafafa;
}
.panel-inverse > .panel-heading,
.panel-success > .panel-heading,
.panel-warning > .panel-heading,
.panel-danger > .panel-heading,
.panel-primary > .panel-heading,
.panel-info > .panel-heading {
  color: #fff;
}
.panel-inverse > .panel-heading {
  background: #242a30;
}
.panel-success > .panel-heading {
  background: #008a8a;
}
.panel-warning > .panel-heading {
  background: #c47d15;
}
.panel-danger > .panel-heading {
  background: #cc4946;
}
.panel-primary > .panel-heading {
  background: #2a72b5;
}
.panel-info > .panel-heading {
  background: #3a92ab;
}
/* Panel Expand */
.panel.panel-expand {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  overflow: hidden;
  z-index: 1080;
  border-radius: 0;
}
.panel.panel-expand .height-xs,
.panel.panel-expand .height-sm,
.panel.panel-expand .height-md,
.panel.panel-expand .height-lg,
.panel.panel-expand .height-full {
  height: 100% !important;
}
.panel.panel-expand > .panel-heading .fa.fa-expand:before {
  content: '\f066';
}
.panel.panel-expand > .panel-heading,
.panel.panel-expand > .panel-body {
  border-radius: 0;
}
.panel.panel-expand > .panel-body {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 40px;
  overflow-y: scroll;
  z-index: 1020;
}
.panel.panel-expand > .panel-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
@keyframes panelExpand {
  from {
    top: 50%;
    left: 50%;
    right: 50%;
    bottom: 50%;
  }
  to {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
@-webkit-keyframes panelExpand {
  from {
    top: 50%;
    left: 50%;
    right: 50%;
    bottom: 50%;
  }
  to {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
/* Table */
.table {
  border-color: #e2e7eb;
  border-radius: 3px;
}
.table > thead > tr > th {
  color: #242a30;
  font-weight: 600;
  border-bottom: 2px solid #e2e7eb !important;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  border-color: #e2e7eb;
  padding: 10px 15px;
}
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 7px 15px;
}
.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
  background: #e8ecf1 !important;
}
.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background: #f0f3f5;
}
.table.table-inverse > thead > tr > th,
.table.table-inverse > tbody > tr > th,
.table.table-inverse > tfoot > tr > th,
.table.table-inverse > thead > tr > td,
.table.table-inverse > tbody > tr > td,
.table.table-inverse > tfoot > tr > td {
  border-color: #999 !important;
  border-color: rgba(0, 0, 0, 0.2) !important;
}
.table.table-inverse,
.table.table-inverse > thead > tr > th,
.table.table-inverse > tbody > tr > th,
.table.table-inverse > tfoot > tr > th {
  color: #ffffff;
}
.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
  background: #dbf0f7;
  border-color: #b6e2ef;
}
.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
  background: #cceeee;
  border-color: #99dede;
}
.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
  background: #ffdedd;
  border-color: #ffbdbc;
}
.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
  background: #fdebd1;
  border-color: #fbd7a3;
}
.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
  background: #f0f3f5;
  border-color: #e2e7e9;
}
/* Predefined Classes */
.row {
  margin: 0 -10px;
}
.row > [class*="col-"] {
  padding: 0 10px;
}
.m-auto {
  margin: 0 auto !important;
}
.wrapper {
  padding: 15px !important;
}
.semi-bold {
  font-weight: 600 !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-visible {
  overflow: visible !important;
}
.overflow-scroll {
  overflow: scroll !important;
}
.overflow-x-hidden {
  overflow-x: hidden !important;
}
.overflow-x-visible {
  overflow-x: visible !important;
}
.overflow-x-scroll {
  overflow-x: scroll !important;
}
.overflow-y-hidden {
  overflow-y: hidden !important;
}
.overflow-y-visible {
  overflow-y: visible !important;
}
.overflow-y-scroll {
  overflow-y: scroll !important;
}
.f-w-100 {
  font-weight: 100 !important;
}
.f-w-200 {
  font-weight: 200 !important;
}
.f-w-300 {
  font-weight: 300 !important;
}
.f-w-400 {
  font-weight: 400 !important;
}
.f-w-500 {
  font-weight: 500 !important;
}
.f-w-600 {
  font-weight: 600 !important;
}
.f-w-700 {
  font-weight: 700 !important;
}
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.pull-left {
  float: left !important;
}
.pull-right {
  float: right !important;
}
.pull-none {
  float: none !important;
}
/* LOOP - Margin & Padding */
.m-40 {
  margin: 40px !important;
}
.m-t-40 {
  margin-top: 40px !important;
}
.m-r-40 {
  margin-right: 40px !important;
}
.m-b-40 {
  margin-bottom: 40px !important;
}
.m-l-40 {
  margin-left: 40px !important;
}
.p-40 {
  padding: 40px !important;
}
.p-t-40 {
  padding-top: 40px !important;
}
.p-r-40 {
  padding-right: 40px !important;
}
.p-b-40 {
  padding-bottom: 40px !important;
}
.p-l-40 {
  padding-left: 40px !important;
}
.m-39 {
  margin: 39px !important;
}
.m-t-39 {
  margin-top: 39px !important;
}
.m-r-39 {
  margin-right: 39px !important;
}
.m-b-39 {
  margin-bottom: 39px !important;
}
.m-l-39 {
  margin-left: 39px !important;
}
.p-39 {
  padding: 39px !important;
}
.p-t-39 {
  padding-top: 39px !important;
}
.p-r-39 {
  padding-right: 39px !important;
}
.p-b-39 {
  padding-bottom: 39px !important;
}
.p-l-39 {
  padding-left: 39px !important;
}
.m-38 {
  margin: 38px !important;
}
.m-t-38 {
  margin-top: 38px !important;
}
.m-r-38 {
  margin-right: 38px !important;
}
.m-b-38 {
  margin-bottom: 38px !important;
}
.m-l-38 {
  margin-left: 38px !important;
}
.p-38 {
  padding: 38px !important;
}
.p-t-38 {
  padding-top: 38px !important;
}
.p-r-38 {
  padding-right: 38px !important;
}
.p-b-38 {
  padding-bottom: 38px !important;
}
.p-l-38 {
  padding-left: 38px !important;
}
.m-37 {
  margin: 37px !important;
}
.m-t-37 {
  margin-top: 37px !important;
}
.m-r-37 {
  margin-right: 37px !important;
}
.m-b-37 {
  margin-bottom: 37px !important;
}
.m-l-37 {
  margin-left: 37px !important;
}
.p-37 {
  padding: 37px !important;
}
.p-t-37 {
  padding-top: 37px !important;
}
.p-r-37 {
  padding-right: 37px !important;
}
.p-b-37 {
  padding-bottom: 37px !important;
}
.p-l-37 {
  padding-left: 37px !important;
}
.m-36 {
  margin: 36px !important;
}
.m-t-36 {
  margin-top: 36px !important;
}
.m-r-36 {
  margin-right: 36px !important;
}
.m-b-36 {
  margin-bottom: 36px !important;
}
.m-l-36 {
  margin-left: 36px !important;
}
.p-36 {
  padding: 36px !important;
}
.p-t-36 {
  padding-top: 36px !important;
}
.p-r-36 {
  padding-right: 36px !important;
}
.p-b-36 {
  padding-bottom: 36px !important;
}
.p-l-36 {
  padding-left: 36px !important;
}
.m-35 {
  margin: 35px !important;
}
.m-t-35 {
  margin-top: 35px !important;
}
.m-r-35 {
  margin-right: 35px !important;
}
.m-b-35 {
  margin-bottom: 35px !important;
}
.m-l-35 {
  margin-left: 35px !important;
}
.p-35 {
  padding: 35px !important;
}
.p-t-35 {
  padding-top: 35px !important;
}
.p-r-35 {
  padding-right: 35px !important;
}
.p-b-35 {
  padding-bottom: 35px !important;
}
.p-l-35 {
  padding-left: 35px !important;
}
.m-34 {
  margin: 34px !important;
}
.m-t-34 {
  margin-top: 34px !important;
}
.m-r-34 {
  margin-right: 34px !important;
}
.m-b-34 {
  margin-bottom: 34px !important;
}
.m-l-34 {
  margin-left: 34px !important;
}
.p-34 {
  padding: 34px !important;
}
.p-t-34 {
  padding-top: 34px !important;
}
.p-r-34 {
  padding-right: 34px !important;
}
.p-b-34 {
  padding-bottom: 34px !important;
}
.p-l-34 {
  padding-left: 34px !important;
}
.m-33 {
  margin: 33px !important;
}
.m-t-33 {
  margin-top: 33px !important;
}
.m-r-33 {
  margin-right: 33px !important;
}
.m-b-33 {
  margin-bottom: 33px !important;
}
.m-l-33 {
  margin-left: 33px !important;
}
.p-33 {
  padding: 33px !important;
}
.p-t-33 {
  padding-top: 33px !important;
}
.p-r-33 {
  padding-right: 33px !important;
}
.p-b-33 {
  padding-bottom: 33px !important;
}
.p-l-33 {
  padding-left: 33px !important;
}
.m-32 {
  margin: 32px !important;
}
.m-t-32 {
  margin-top: 32px !important;
}
.m-r-32 {
  margin-right: 32px !important;
}
.m-b-32 {
  margin-bottom: 32px !important;
}
.m-l-32 {
  margin-left: 32px !important;
}
.p-32 {
  padding: 32px !important;
}
.p-t-32 {
  padding-top: 32px !important;
}
.p-r-32 {
  padding-right: 32px !important;
}
.p-b-32 {
  padding-bottom: 32px !important;
}
.p-l-32 {
  padding-left: 32px !important;
}
.m-31 {
  margin: 31px !important;
}
.m-t-31 {
  margin-top: 31px !important;
}
.m-r-31 {
  margin-right: 31px !important;
}
.m-b-31 {
  margin-bottom: 31px !important;
}
.m-l-31 {
  margin-left: 31px !important;
}
.p-31 {
  padding: 31px !important;
}
.p-t-31 {
  padding-top: 31px !important;
}
.p-r-31 {
  padding-right: 31px !important;
}
.p-b-31 {
  padding-bottom: 31px !important;
}
.p-l-31 {
  padding-left: 31px !important;
}
.m-30 {
  margin: 30px !important;
}
.m-t-30 {
  margin-top: 30px !important;
}
.m-r-30 {
  margin-right: 30px !important;
}
.m-b-30 {
  margin-bottom: 30px !important;
}
.m-l-30 {
  margin-left: 30px !important;
}
.p-30 {
  padding: 30px !important;
}
.p-t-30 {
  padding-top: 30px !important;
}
.p-r-30 {
  padding-right: 30px !important;
}
.p-b-30 {
  padding-bottom: 30px !important;
}
.p-l-30 {
  padding-left: 30px !important;
}
.m-29 {
  margin: 29px !important;
}
.m-t-29 {
  margin-top: 29px !important;
}
.m-r-29 {
  margin-right: 29px !important;
}
.m-b-29 {
  margin-bottom: 29px !important;
}
.m-l-29 {
  margin-left: 29px !important;
}
.p-29 {
  padding: 29px !important;
}
.p-t-29 {
  padding-top: 29px !important;
}
.p-r-29 {
  padding-right: 29px !important;
}
.p-b-29 {
  padding-bottom: 29px !important;
}
.p-l-29 {
  padding-left: 29px !important;
}
.m-28 {
  margin: 28px !important;
}
.m-t-28 {
  margin-top: 28px !important;
}
.m-r-28 {
  margin-right: 28px !important;
}
.m-b-28 {
  margin-bottom: 28px !important;
}
.m-l-28 {
  margin-left: 28px !important;
}
.p-28 {
  padding: 28px !important;
}
.p-t-28 {
  padding-top: 28px !important;
}
.p-r-28 {
  padding-right: 28px !important;
}
.p-b-28 {
  padding-bottom: 28px !important;
}
.p-l-28 {
  padding-left: 28px !important;
}
.m-27 {
  margin: 27px !important;
}
.m-t-27 {
  margin-top: 27px !important;
}
.m-r-27 {
  margin-right: 27px !important;
}
.m-b-27 {
  margin-bottom: 27px !important;
}
.m-l-27 {
  margin-left: 27px !important;
}
.p-27 {
  padding: 27px !important;
}
.p-t-27 {
  padding-top: 27px !important;
}
.p-r-27 {
  padding-right: 27px !important;
}
.p-b-27 {
  padding-bottom: 27px !important;
}
.p-l-27 {
  padding-left: 27px !important;
}
.m-26 {
  margin: 26px !important;
}
.m-t-26 {
  margin-top: 26px !important;
}
.m-r-26 {
  margin-right: 26px !important;
}
.m-b-26 {
  margin-bottom: 26px !important;
}
.m-l-26 {
  margin-left: 26px !important;
}
.p-26 {
  padding: 26px !important;
}
.p-t-26 {
  padding-top: 26px !important;
}
.p-r-26 {
  padding-right: 26px !important;
}
.p-b-26 {
  padding-bottom: 26px !important;
}
.p-l-26 {
  padding-left: 26px !important;
}
.m-25 {
  margin: 25px !important;
}
.m-t-25 {
  margin-top: 25px !important;
}
.m-r-25 {
  margin-right: 25px !important;
}
.m-b-25 {
  margin-bottom: 25px !important;
}
.m-l-25 {
  margin-left: 25px !important;
}
.p-25 {
  padding: 25px !important;
}
.p-t-25 {
  padding-top: 25px !important;
}
.p-r-25 {
  padding-right: 25px !important;
}
.p-b-25 {
  padding-bottom: 25px !important;
}
.p-l-25 {
  padding-left: 25px !important;
}
.m-24 {
  margin: 24px !important;
}
.m-t-24 {
  margin-top: 24px !important;
}
.m-r-24 {
  margin-right: 24px !important;
}
.m-b-24 {
  margin-bottom: 24px !important;
}
.m-l-24 {
  margin-left: 24px !important;
}
.p-24 {
  padding: 24px !important;
}
.p-t-24 {
  padding-top: 24px !important;
}
.p-r-24 {
  padding-right: 24px !important;
}
.p-b-24 {
  padding-bottom: 24px !important;
}
.p-l-24 {
  padding-left: 24px !important;
}
.m-23 {
  margin: 23px !important;
}
.m-t-23 {
  margin-top: 23px !important;
}
.m-r-23 {
  margin-right: 23px !important;
}
.m-b-23 {
  margin-bottom: 23px !important;
}
.m-l-23 {
  margin-left: 23px !important;
}
.p-23 {
  padding: 23px !important;
}
.p-t-23 {
  padding-top: 23px !important;
}
.p-r-23 {
  padding-right: 23px !important;
}
.p-b-23 {
  padding-bottom: 23px !important;
}
.p-l-23 {
  padding-left: 23px !important;
}
.m-22 {
  margin: 22px !important;
}
.m-t-22 {
  margin-top: 22px !important;
}
.m-r-22 {
  margin-right: 22px !important;
}
.m-b-22 {
  margin-bottom: 22px !important;
}
.m-l-22 {
  margin-left: 22px !important;
}
.p-22 {
  padding: 22px !important;
}
.p-t-22 {
  padding-top: 22px !important;
}
.p-r-22 {
  padding-right: 22px !important;
}
.p-b-22 {
  padding-bottom: 22px !important;
}
.p-l-22 {
  padding-left: 22px !important;
}
.m-21 {
  margin: 21px !important;
}
.m-t-21 {
  margin-top: 21px !important;
}
.m-r-21 {
  margin-right: 21px !important;
}
.m-b-21 {
  margin-bottom: 21px !important;
}
.m-l-21 {
  margin-left: 21px !important;
}
.p-21 {
  padding: 21px !important;
}
.p-t-21 {
  padding-top: 21px !important;
}
.p-r-21 {
  padding-right: 21px !important;
}
.p-b-21 {
  padding-bottom: 21px !important;
}
.p-l-21 {
  padding-left: 21px !important;
}
.m-20 {
  margin: 20px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-r-20 {
  margin-right: 20px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-l-20 {
  margin-left: 20px !important;
}
.p-20 {
  padding: 20px !important;
}
.p-t-20 {
  padding-top: 20px !important;
}
.p-r-20 {
  padding-right: 20px !important;
}
.p-b-20 {
  padding-bottom: 20px !important;
}
.p-l-20 {
  padding-left: 20px !important;
}
.m-19 {
  margin: 19px !important;
}
.m-t-19 {
  margin-top: 19px !important;
}
.m-r-19 {
  margin-right: 19px !important;
}
.m-b-19 {
  margin-bottom: 19px !important;
}
.m-l-19 {
  margin-left: 19px !important;
}
.p-19 {
  padding: 19px !important;
}
.p-t-19 {
  padding-top: 19px !important;
}
.p-r-19 {
  padding-right: 19px !important;
}
.p-b-19 {
  padding-bottom: 19px !important;
}
.p-l-19 {
  padding-left: 19px !important;
}
.m-18 {
  margin: 18px !important;
}
.m-t-18 {
  margin-top: 18px !important;
}
.m-r-18 {
  margin-right: 18px !important;
}
.m-b-18 {
  margin-bottom: 18px !important;
}
.m-l-18 {
  margin-left: 18px !important;
}
.p-18 {
  padding: 18px !important;
}
.p-t-18 {
  padding-top: 18px !important;
}
.p-r-18 {
  padding-right: 18px !important;
}
.p-b-18 {
  padding-bottom: 18px !important;
}
.p-l-18 {
  padding-left: 18px !important;
}
.m-17 {
  margin: 17px !important;
}
.m-t-17 {
  margin-top: 17px !important;
}
.m-r-17 {
  margin-right: 17px !important;
}
.m-b-17 {
  margin-bottom: 17px !important;
}
.m-l-17 {
  margin-left: 17px !important;
}
.p-17 {
  padding: 17px !important;
}
.p-t-17 {
  padding-top: 17px !important;
}
.p-r-17 {
  padding-right: 17px !important;
}
.p-b-17 {
  padding-bottom: 17px !important;
}
.p-l-17 {
  padding-left: 17px !important;
}
.m-16 {
  margin: 16px !important;
}
.m-t-16 {
  margin-top: 16px !important;
}
.m-r-16 {
  margin-right: 16px !important;
}
.m-b-16 {
  margin-bottom: 16px !important;
}
.m-l-16 {
  margin-left: 16px !important;
}
.p-16 {
  padding: 16px !important;
}
.p-t-16 {
  padding-top: 16px !important;
}
.p-r-16 {
  padding-right: 16px !important;
}
.p-b-16 {
  padding-bottom: 16px !important;
}
.p-l-16 {
  padding-left: 16px !important;
}
.m-15 {
  margin: 15px !important;
}
.m-t-15 {
  margin-top: 15px !important;
}
.m-r-15 {
  margin-right: 15px !important;
}
.m-b-15 {
  margin-bottom: 15px !important;
}
.m-l-15 {
  margin-left: 15px !important;
}
.p-15 {
  padding: 15px !important;
}
.p-t-15 {
  padding-top: 15px !important;
}
.p-r-15 {
  padding-right: 15px !important;
}
.p-b-15 {
  padding-bottom: 15px !important;
}
.p-l-15 {
  padding-left: 15px !important;
}
.m-14 {
  margin: 14px !important;
}
.m-t-14 {
  margin-top: 14px !important;
}
.m-r-14 {
  margin-right: 14px !important;
}
.m-b-14 {
  margin-bottom: 14px !important;
}
.m-l-14 {
  margin-left: 14px !important;
}
.p-14 {
  padding: 14px !important;
}
.p-t-14 {
  padding-top: 14px !important;
}
.p-r-14 {
  padding-right: 14px !important;
}
.p-b-14 {
  padding-bottom: 14px !important;
}
.p-l-14 {
  padding-left: 14px !important;
}
.m-13 {
  margin: 13px !important;
}
.m-t-13 {
  margin-top: 13px !important;
}
.m-r-13 {
  margin-right: 13px !important;
}
.m-b-13 {
  margin-bottom: 13px !important;
}
.m-l-13 {
  margin-left: 13px !important;
}
.p-13 {
  padding: 13px !important;
}
.p-t-13 {
  padding-top: 13px !important;
}
.p-r-13 {
  padding-right: 13px !important;
}
.p-b-13 {
  padding-bottom: 13px !important;
}
.p-l-13 {
  padding-left: 13px !important;
}
.m-12 {
  margin: 12px !important;
}
.m-t-12 {
  margin-top: 12px !important;
}
.m-r-12 {
  margin-right: 12px !important;
}
.m-b-12 {
  margin-bottom: 12px !important;
}
.m-l-12 {
  margin-left: 12px !important;
}
.p-12 {
  padding: 12px !important;
}
.p-t-12 {
  padding-top: 12px !important;
}
.p-r-12 {
  padding-right: 12px !important;
}
.p-b-12 {
  padding-bottom: 12px !important;
}
.p-l-12 {
  padding-left: 12px !important;
}
.m-11 {
  margin: 11px !important;
}
.m-t-11 {
  margin-top: 11px !important;
}
.m-r-11 {
  margin-right: 11px !important;
}
.m-b-11 {
  margin-bottom: 11px !important;
}
.m-l-11 {
  margin-left: 11px !important;
}
.p-11 {
  padding: 11px !important;
}
.p-t-11 {
  padding-top: 11px !important;
}
.p-r-11 {
  padding-right: 11px !important;
}
.p-b-11 {
  padding-bottom: 11px !important;
}
.p-l-11 {
  padding-left: 11px !important;
}
.m-10 {
  margin: 10px !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-r-10 {
  margin-right: 10px !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.m-l-10 {
  margin-left: 10px !important;
}
.p-10 {
  padding: 10px !important;
}
.p-t-10 {
  padding-top: 10px !important;
}
.p-r-10 {
  padding-right: 10px !important;
}
.p-b-10 {
  padding-bottom: 10px !important;
}
.p-l-10 {
  padding-left: 10px !important;
}
.m-9 {
  margin: 9px !important;
}
.m-t-9 {
  margin-top: 9px !important;
}
.m-r-9 {
  margin-right: 9px !important;
}
.m-b-9 {
  margin-bottom: 9px !important;
}
.m-l-9 {
  margin-left: 9px !important;
}
.p-9 {
  padding: 9px !important;
}
.p-t-9 {
  padding-top: 9px !important;
}
.p-r-9 {
  padding-right: 9px !important;
}
.p-b-9 {
  padding-bottom: 9px !important;
}
.p-l-9 {
  padding-left: 9px !important;
}
.m-8 {
  margin: 8px !important;
}
.m-t-8 {
  margin-top: 8px !important;
}
.m-r-8 {
  margin-right: 8px !important;
}
.m-b-8 {
  margin-bottom: 8px !important;
}
.m-l-8 {
  margin-left: 8px !important;
}
.p-8 {
  padding: 8px !important;
}
.p-t-8 {
  padding-top: 8px !important;
}
.p-r-8 {
  padding-right: 8px !important;
}
.p-b-8 {
  padding-bottom: 8px !important;
}
.p-l-8 {
  padding-left: 8px !important;
}
.m-7 {
  margin: 7px !important;
}
.m-t-7 {
  margin-top: 7px !important;
}
.m-r-7 {
  margin-right: 7px !important;
}
.m-b-7 {
  margin-bottom: 7px !important;
}
.m-l-7 {
  margin-left: 7px !important;
}
.p-7 {
  padding: 7px !important;
}
.p-t-7 {
  padding-top: 7px !important;
}
.p-r-7 {
  padding-right: 7px !important;
}
.p-b-7 {
  padding-bottom: 7px !important;
}
.p-l-7 {
  padding-left: 7px !important;
}
.m-6 {
  margin: 6px !important;
}
.m-t-6 {
  margin-top: 6px !important;
}
.m-r-6 {
  margin-right: 6px !important;
}
.m-b-6 {
  margin-bottom: 6px !important;
}
.m-l-6 {
  margin-left: 6px !important;
}
.p-6 {
  padding: 6px !important;
}
.p-t-6 {
  padding-top: 6px !important;
}
.p-r-6 {
  padding-right: 6px !important;
}
.p-b-6 {
  padding-bottom: 6px !important;
}
.p-l-6 {
  padding-left: 6px !important;
}
.m-5 {
  margin: 5px !important;
}
.m-t-5 {
  margin-top: 5px !important;
}
.m-r-5 {
  margin-right: 5px !important;
}
.m-b-5 {
  margin-bottom: 5px !important;
}
.m-l-5 {
  margin-left: 5px !important;
}
.p-5 {
  padding: 5px !important;
}
.p-t-5 {
  padding-top: 5px !important;
}
.p-r-5 {
  padding-right: 5px !important;
}
.p-b-5 {
  padding-bottom: 5px !important;
}
.p-l-5 {
  padding-left: 5px !important;
}
.m-4 {
  margin: 4px !important;
}
.m-t-4 {
  margin-top: 4px !important;
}
.m-r-4 {
  margin-right: 4px !important;
}
.m-b-4 {
  margin-bottom: 4px !important;
}
.m-l-4 {
  margin-left: 4px !important;
}
.p-4 {
  padding: 4px !important;
}
.p-t-4 {
  padding-top: 4px !important;
}
.p-r-4 {
  padding-right: 4px !important;
}
.p-b-4 {
  padding-bottom: 4px !important;
}
.p-l-4 {
  padding-left: 4px !important;
}
.m-3 {
  margin: 3px !important;
}
.m-t-3 {
  margin-top: 3px !important;
}
.m-r-3 {
  margin-right: 3px !important;
}
.m-b-3 {
  margin-bottom: 3px !important;
}
.m-l-3 {
  margin-left: 3px !important;
}
.p-3 {
  padding: 3px !important;
}
.p-t-3 {
  padding-top: 3px !important;
}
.p-r-3 {
  padding-right: 3px !important;
}
.p-b-3 {
  padding-bottom: 3px !important;
}
.p-l-3 {
  padding-left: 3px !important;
}
.m-2 {
  margin: 2px !important;
}
.m-t-2 {
  margin-top: 2px !important;
}
.m-r-2 {
  margin-right: 2px !important;
}
.m-b-2 {
  margin-bottom: 2px !important;
}
.m-l-2 {
  margin-left: 2px !important;
}
.p-2 {
  padding: 2px !important;
}
.p-t-2 {
  padding-top: 2px !important;
}
.p-r-2 {
  padding-right: 2px !important;
}
.p-b-2 {
  padding-bottom: 2px !important;
}
.p-l-2 {
  padding-left: 2px !important;
}
.m-1 {
  margin: 1px !important;
}
.m-t-1 {
  margin-top: 1px !important;
}
.m-r-1 {
  margin-right: 1px !important;
}
.m-b-1 {
  margin-bottom: 1px !important;
}
.m-l-1 {
  margin-left: 1px !important;
}
.p-1 {
  padding: 1px !important;
}
.p-t-1 {
  padding-top: 1px !important;
}
.p-r-1 {
  padding-right: 1px !important;
}
.p-b-1 {
  padding-bottom: 1px !important;
}
.p-l-1 {
  padding-left: 1px !important;
}
.m-0 {
  margin: 0px !important;
}
.m-t-0 {
  margin-top: 0px !important;
}
.m-r-0 {
  margin-right: 0px !important;
}
.m-b-0 {
  margin-bottom: 0px !important;
}
.m-l-0 {
  margin-left: 0px !important;
}
.p-0 {
  padding: 0px !important;
}
.p-t-0 {
  padding-top: 0px !important;
}
.p-r-0 {
  padding-right: 0px !important;
}
.p-b-0 {
  padding-bottom: 0px !important;
}
.p-l-0 {
  padding-left: 0px !important;
}
/* LOOP - Font Size */
.f-s-20 {
  font-size: 20px !important;
}
.f-s-19 {
  font-size: 19px !important;
}
.f-s-18 {
  font-size: 18px !important;
}
.f-s-17 {
  font-size: 17px !important;
}
.f-s-16 {
  font-size: 16px !important;
}
.f-s-15 {
  font-size: 15px !important;
}
.f-s-14 {
  font-size: 14px !important;
}
.f-s-13 {
  font-size: 13px !important;
}
.f-s-12 {
  font-size: 12px !important;
}
.f-s-11 {
  font-size: 11px !important;
}
.f-s-10 {
  font-size: 10px !important;
}
.f-s-9 {
  font-size: 9px !important;
}
.f-s-8 {
  font-size: 8px !important;
}
.table-valign-middle th,
.table-valign-middle td {
  vertical-align: middle !important;
}
.table-th-valign-middle th,
.table-td-valign-middle td {
  vertical-align: middle !important;
}
.table-valign-top th,
.table-valign-top td {
  vertical-align: top !important;
}
.table-th-valign-top th,
.table-td-valign-top td {
  vertical-align: top !important;
}
.table-valign-bottom th,
.table-valign-bottom td {
  vertical-align: bottom !important;
}
.table-th-valign-bottom th,
.table-td-valign-bottom td {
  vertical-align: bottom !important;
}
.text-inverse {
  color: #2d353c !important;
}
a.text-inverse:hover,
a.text-inverse:focus {
  color: #575d63 !important;
}
.text-success {
  color: #00acac !important;
}
a.text-success:hover,
a.text-success:focus {
  color: #33bdbd !important;
}
.text-info {
  color: #49b6d6 !important;
}
a.text-info:hover,
a.text-info:focus {
  color: #6dc5de !important;
}
.text-primary {
  color: #348fe2 !important;
}
a.text-primary:hover,
a.text-primary:focus {
  color: #5da5e8 !important;
}
.text-warning {
  color: #e3fa3e !important;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #e9fb65 !important;
}
.text-danger {
  color: #ff5b57 !important;
}
a.text-danger:hover,
a.text-danger:focus {
  color: #ff7c79 !important;
}
.text-white {
  color: #fff !important;
}
a.text-white:hover,
a.text-white:focus {
  color: #f0f3f4 !important;
}
.bg-white {
  background: #ffffff !important;
}
.bg-silver-lighter {
  background: #f4f6f7 !important;
}
.bg-silver {
  background: #f0f3f4 !important;
}
.bg-silver-darker {
  background: #b4b6b7 !important;
}
.bg-black {
  background: #2d353c !important;
}
.bg-black-darker {
  background: #242a30 !important;
}
.bg-black-lighter {
  background: #575d63 !important;
}
.bg-grey {
  background: #b6c2c9 !important;
}
.bg-grey-darker {
  background: #929ba1 !important;
}
.bg-grey-lighter {
  background: #c5ced4 !important;
}
.bg-red {
  background: #ff5b57 !important;
}
.bg-red-darker {
  background: #cc4946 !important;
}
.bg-red-lighter {
  background: #ff7c79 !important;
}
.bg-orange {
  background: #f59c1a !important;
}
.bg-orange-darker {
  background: #c47d15 !important;
}
.bg-orange-lighter {
  background: #f7b048 !important;
}
.bg-yellow {
  background: #e3fa3e !important;
}
.bg-yellow-darker {
  background: #b6c832 !important;
}
.bg-yellow-lighter {
  background: #e9fb65 !important;
}
.bg-green {
  background: #00acac !important;
}
.bg-green-darker {
  background: #008a8a !important;
}
.bg-green-lighter {
  background: #33bdbd !important;
}
.bg-blue {
  background: #348fe2 !important;
}
.bg-blue-darker {
  background: #2a72b5 !important;
}
.bg-blue-lighter {
  background: #5da5e8 !important;
}
.bg-aqua {
  background: #49b6d6 !important;
}
.bg-aqua-darker {
  background: #3a92ab !important;
}
.bg-aqua-lighter {
  background: #6dc5de !important;
}
.bg-purple {
  background: #727cb6 !important;
}
.bg-purple-darker {
  background: #5b6392 !important;
}
.bg-purple-lighter {
  background: #8e96c5 !important;
}
.no-bg {
  background: none !important;
}
.height-xs {
  height: 150px !important;
}
.height-sm {
  height: 300px !important;
}
.height-md {
  height: 450px !important;
}
.height-lg {
  height: 600px !important;
}
.height-full {
  height: 100% !important;
}
.height-50 {
  height: 50px !important;
}
.height-100 {
  height: 100px !important;
}
.height-150 {
  height: 150px !important;
}
.height-200 {
  height: 200px !important;
}
.height-250 {
  height: 250px !important;
}
.height-300 {
  height: 300px !important;
}
.height-350 {
  height: 350px !important;
}
.height-400 {
  height: 400px !important;
}
.height-450 {
  height: 450px !important;
}
.height-500 {
  height: 500px !important;
}
.height-550 {
  height: 550px !important;
}
.height-600 {
  height: 600px !important;
}
.width-xs {
  width: 150px !important;
}
.width-sm {
  width: 300px !important;
}
.width-md {
  width: 450px !important;
}
.width-lg {
  width: 600px !important;
}
.width-full {
  width: 100% !important;
}
.width-50 {
  width: 50px !important;
}
.width-100 {
  width: 100px !important;
}
.width-150 {
  width: 150px !important;
}
.width-200 {
  width: 200px !important;
}
.width-250 {
  width: 250px !important;
}
.width-300 {
  width: 300px !important;
}
.width-350 {
  width: 350px !important;
}
.width-400 {
  width: 400px !important;
}
.width-450 {
  width: 450px !important;
}
.width-500 {
  width: 500px !important;
}
.width-550 {
  width: 550px !important;
}
.width-600 {
  width: 600px !important;
}
.text-ellipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.underline {
  border-bottom: 1px solid #e2e7eb !important;
}
.inline {
  display: inline;
}
/* Responsive Setting */
@media (max-width: 979px) {
  .form-horizontal.form-bordered .form-group {
    border-bottom: 1px solid #eee;
  }
  .form-horizontal.form-bordered .form-group .control-label {
    padding: 15px;
    line-height: 34px;
  }
  .form-horizontal.form-bordered .form-group > div {
    padding: 15px;
  }
  .form-horizontal.form-bordered .form-group,
  .form-horizontal.form-bordered .form-group > div,
  .form-horizontal.form-bordered .form-group > .control-label {
    border: none;
  }
}
@media (max-width: 767px) {
  .table-responsive .table > thead > tr > th,
  .table-responsive .table > tbody > tr > th,
  .table-responsive .table > tfoot > tr > th,
  .table-responsive .table > thead > tr > td,
  .table-responsive .table > tbody > tr > td,
  .table-responsive .table > tfoot > tr > td {
    white-space: nowrap;
  }
  .form-horizontal.form-bordered .form-group > .control-label {
    padding: 15px 15px 0;
    line-height: inherit;
  }
  .form-horizontal.form-bordered .form-group > div {
    padding: 5px 15px 15px;
  }
  .theme-panel {
    top: 0;
    bottom: 0;
  }
  .theme-panel .theme-collapse-btn {
    top: 150px;
  }
  .theme-panel .theme-panel-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    overflow: scroll;
  }
}

/*# sourceMappingURL=tableview-a.css.map */